import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    _initFirebase = false;

    constructor(props) {
      super(props);

      this._isMounted = false;

      this.state = {
        authUser: null,
      };
    }

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;
        this.listener = this.props.firebase.onAuthUserListener(
          async (authUser) => {
            let authenticatedUser = authUser;
            // in rare cases the local user does not
            // catch up with data on anonymous init
            // i.e. only this is returned:
            // {"uid":"...","email":null,"emailVerified":false,"providerData":[],"isAnonymous":true,"isAnonymousInitiated":true,"referralProgram":{"recommendedBy":1}}
            if (!authUser.accessLevel || !authUser.study) {
              try {
                authenticatedUser = await this.props.firebase.getCurrentUser();
              } catch (error) {
                console.warn(error);
              }
            }
            localStorage.setItem(
              'authUser',
              JSON.stringify(authenticatedUser),
            );
            this._isMounted && this.setState({ authUser: authenticatedUser });
            delete window._______rebusmetod_initAnonymous;
          },
          () => {
            setTimeout(() => {
              delete window._______rebusmetod_initAnonymous;
            }, 100);
            localStorage.removeItem('authUser');
            this.setState({ authUser: null });
          },
        );
      }
    };

    componentDidMount() {
      this._isMounted = true;
      const localStorageUser = JSON.parse(
        localStorage.getItem('authUser'),
      );
      this.setState({
        authUser: localStorageUser,
      });

      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this._isMounted = false;
      this._initFirebase = false;
      this.listener && this.listener();
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
