export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const FREE_LESSONS = '/podarunkovi-uroky';
export const PAID_LESSONS = '/ya-chytayu';
export const LESSONS_PLAN = '/ya-pochynayu-chytaty';
