import React, { Component } from 'react';
import { AuthUserContext } from '../../../utils/Session';

import FooterNavigationAuth from './atoms/FooterNavigationAuth';

class FooterNavigation extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => <FooterNavigationAuth authUser={authUser} />}
      </AuthUserContext.Consumer>
    );
  }
}

export default FooterNavigation;
