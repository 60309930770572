import React from 'react';
import { Link } from 'gatsby';
import {
  LANDING,
  HOME,
  ACCOUNT,
  LESSONS_PLAN,
} from '../../../../constants/routes';
import SignOutButton from './SignOutButton';
import Logo from './../../../../../src/assets/svg/rebus-metod-logo.svg';

const NavigationAuth = (props) => (
  <nav className="navbar" id="main-nav" aria-label="Main">
    <div className="navbar__inner">
      <div className="navbar__user">
        <div className="navbar__item">
          <SignOutButton authUser={props.authUser} />
        </div>
      </div>
      <div className="navbar__center">
        <div className="navbar__links">
          <div className="navbar__link">
            <Link to={LANDING} activeClassName="active">
              Головна
            </Link>
          </div>
          <div className="navbar__link">
            <Link to={LESSONS_PLAN} activeClassName="active">
              План уроків
            </Link>
          </div>
          <div className="navbar__link">
            <Link to={LESSONS_PLAN} activeClassName="active">
              Як придбати?
            </Link>
          </div>
          {props.authUser && !props.authUser.isAnonymous && (<div className="navbar__link">
            <Link to={ACCOUNT} activeClassName="active">
              Акаунт
            </Link>
          </div>)}
        </div>
      </div>
      <div className="navbar__logo">
        <Link to={LANDING} activeClassName="active">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
    </div>
  </nav>
);

export default NavigationAuth;
