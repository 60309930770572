import React, { Component } from 'react';
import { AuthUserContext } from '../../../utils/Session';

import NavigationAuth from './atoms/NavigationAuth';
import NavigationNonAuth from './atoms/NavigationNonAuth';

class Navigation extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {(authUser) => <NavigationAuth authUser={authUser} />}
      </AuthUserContext.Consumer>
    );
  }
}

export default Navigation;
