import React from 'react';
import { Link } from 'gatsby';
import {
  LANDING,
  HOME,
  ACCOUNT,
  LESSONS_PLAN,
} from '../../../../constants/routes';

const FooterNavigationAuth = (props) => (
  <nav className="navbar navbar__secondary" id="main-nav-secondary" aria-label="Secondary">
    <div className="navbar__inner navbar__secondary-inner">
      <div className="navbar__center">
        <div className="navbar__links">
          <div className="navbar__link">
            <Link to={LESSONS_PLAN} activeClassName="active">
              Як придбати?
            </Link>
          </div>
          <div className="navbar__link">
            <Link to={LESSONS_PLAN} activeClassName="active">
              Партнерська програма
            </Link>
          </div>
          <div className="navbar__link">
            <Link to={HOME} activeClassName="active">
              Статті
            </Link>
          </div>
          {props.authUser && !props.authUser.isAnonymous && (<div className="navbar__link">
            <Link to={ACCOUNT} activeClassName="active">
              Акаунт
            </Link>
          </div>)}
        </div>
      </div>
    </div>
  </nav>
);

export default FooterNavigationAuth;
