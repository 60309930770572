import React from 'react';
import { navigate } from 'gatsby';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { SIGN_IN, LESSONS_PLAN } from '../../constants/routes';

const INITIAL_STATE = {
  error: null,
};

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    _initFirebase = false;
    _initAnonymous = false;

    firebaseInit = () => {
      if (this.props.firebase && !this._initFirebase) {
        this._initFirebase = true;
        const self = this;

        this.listener = this.props.firebase.onAuthUserListener(
          (authUser) => {
            if (!condition(authUser)) {
              navigate(SIGN_IN);
            }
          },
          async () => {
            try {
              self.setState({ ...INITIAL_STATE });
              const localUser = await JSON.parse(
                localStorage.getItem('authUser'),
              );
              let anonymous = null;
              if (
                !self._initAnonymous &&
                !window._______rebusmetod_initAnonymous
              ) {
                self._initAnonymous = true;
                window._______rebusmetod_initAnonymous = true;
                anonymous = await self.props.firebase.doCreateAnonymousUser();
                window._______rebusmetod_initAnonymous =
                  anonymous.user.uid;
                localStorage.setItem(
                  'authUser',
                  JSON.stringify(anonymous),
                );
              }
            } catch (error) {
              self.setState({ error });
            }
          },
        );
      }
    };

    componentDidMount() {
      this.firebaseInit();
    }

    componentDidUpdate() {
      this.firebaseInit();
    }

    componentWillUnmount() {
      this.listener && this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            condition(authUser) ? <Component {...this.props} /> : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithAuthorization);
};

export default withAuthorization;
