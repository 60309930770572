import React from 'react';
import { navigate } from 'gatsby';

import { withFirebase } from '../../../../utils/Firebase';
import { SIGN_IN } from '../../../../constants/routes';
import DefaultAvatar from './../../../../../src/assets/svg/default-avatar.svg';

const SignOutButton = ({ firebase, authUser }) => {
  const isAnonymousUser =
    !authUser || (authUser && authUser.isAnonymous);
  const avatarUrl = authUser
    ? authUser.avatarUrl
      ? authUser.avatarUrl
      : `https://avatars.dicebear.com/api/avataaars/${authUser.uid}.svg?options[mouth][]=smile&options[eyes][]=happy&options[skin][]=light`
    : DefaultAvatar;
  //  authUser.isAnonymousInitiated;
  return (
    <>
      {avatarUrl ? <img src={avatarUrl} className={`avatar ${(authUser || isAnonymousUser && authUser && !authUser.avatarUrl) ? 'slide-up' : 'prehidden'}`} referrerPolicy="no-referrer" /> : null}
      <a
        className="signout-btn"
        onClick={() => {          
            firebase && !isAnonymousUser && firebase.doSignOut();
            navigate(SIGN_IN);
          }
        }
      >
        {isAnonymousUser ? 'Зареєструватися / Увійти' : 'Вийти'}
      </a>
    </>
  );
};

export default withFirebase(SignOutButton);
