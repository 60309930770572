import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = (authUser) =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map((provider) => provider.providerId)
    .includes('password');

const withEmailVerification = (Component) => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            needsEmailVerification(authUser) ? (
              <>
              <div className="email-verification container">
                {this.state.isSent ? (
                    <div>
                      <p>
                        <strong>✅ Листа надіслано:</strong> Перевірте свою скриньку, включаючи папку «Спам» (позначте листа як «не спам» в такому разі).
                      </p>
                      <p>
                        Оновіть цю сторінку після переходу за посиланням з листа.
                      </p>
                  </div>
                ) : (
                  <div>
                  <p>
                    Підтвердьте адресу електронної пошти, перейшовши за посиланням, що міститься у листі від «Ребус-методу».
                  </p>
                  <p>
                    Перевірте свою скриньку, включаючи папку «Спам» (позначте листа як «не спам» в такому разі). Натисніть кнопку внизу, щоб надіслати листа повторно.
                  </p>
                  </div>
                )}

                <button
                  type="button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Надіслати листа із підтвердженням
                </button>
                </div>
                <Component {...this.props} />
                </>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
