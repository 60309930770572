module.exports = {
  apiKey: "AIzaSyAZES4z28oLUxjtKioexKzX1nc4_v8ewc4",
  authDomain: "rebus-gatsby.firebaseapp.com",
  databaseURL: "https://rebus-gatsby.firebaseio.com",
  projectId: "rebus-gatsby",
  storageBucket: "rebus-gatsby.appspot.com",
  messagingSenderId: "476357106743",
  appId: "1:476357106743:web:6a584c5de5e69e57e7b3b2",
  measurementId: "G-3BXFH87VLM"
};
