import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/functions';

import config from '../../../firebaseConfig';

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.database = app.database();
    this.firestore = app.firestore();
    this.functions = app.functions();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doCreateAnonymousUser = async () => {
    return await this.auth.signInAnonymously();
  };

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) =>
    this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: window.location.href,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** User API ***

  users = () => this.database.ref('users');

  getCurrentUser = () => this.auth.currentUser;
  
  databaseUser = (uid, path = '') =>
    this.database.ref(`users/${uid}${path}`);

  doDBFieldUpdate = (path, data) =>
    this.database
      .ref(path)
      .set(data)
      .catch((error) => {
        console.error(error);
      });

  firestoreUser = (uid) => this.firestore.doc(`users/${uid}`);

  firestoreUsers = () => this.firestore.collection('users');

  posts = () => this.firestore.collection('posts');

  post = (post) => this.posts().where('slug', '==', post.slug);

  // *** Merge Auth and DB User API *** //
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        if (authUser.isAnonymous) {
          await this.doDBFieldUpdate(
            `users/${authUser.uid}/isAnonymousInitiated`,
            true,
          );
          await this.doDBFieldUpdate(
            `users/${authUser.uid}/referralProgram/recommendedBy`,
            1,
          );
          await this.doDBFieldUpdate(
            `users/${authUser.uid}/isAnonymous`,
            true,
          );
          await this.firestoreUser(authUser.uid).set({
            updatedAt: new Date().getTime(),
            updatedAtDate: new Date().toJSON(),
            isAnonymous: true,
            uid: authUser.uid,
          });
        }
        await this.databaseUser(authUser.uid)
          .once('value')
          .then(async (snapshot) => {
            const databaseUser = await snapshot.val();
            // merge auth and database user
            authUser = {
              ...{
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
              },
              ...databaseUser,
            };

            await next(authUser);
          });
      } else {
        await fallback();
      }
    });
}

let firebase;

function getFirebase(app, auth, database) {
  if (!firebase) {
    firebase = new Firebase(app, auth, database);
  }
  return firebase;
}

export default getFirebase;
